body {
  background-color: #f2f2f2;
  height: 100Vh;
}

.sign {
  display: flex;
  width: 100%;
  height: 100vh;
}

.pics {
  background-image: url(../assets/setfront.svg);
  height: 100%;
  background-repeat: no-repeat;
  width: 50%;
  background-position: center;
  background-color: #133856;
  background-size: contain;
}

.logo {
  display: flex;
  justify-content: center;
  /* outline: 2px solid red; */
  align-items: center;
  padding-top: 10rem;
}

.formy {
  height: auto;
  width: 50%;
}

.formy h2 {
  color: var(--Text-Color-1, #1b1b1b);
  font-family: Plus Jakarta Sans;
  font-size: 39px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 46.8px */
  text-align: center;
  padding-top: 2rem;
}


.google p {
  color: var(--Text-Color-1, #1b1b1b);
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 19.2px */
}

.google {
  display: flex;
  padding: 13px 20px 13px 20px;
  width: 80%;
  margin: auto;
  margin-top: 2rem;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #fff;
  box-shadow: 4px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.login {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: auto;
  margin-top: 2rem;
}

.log {
  color: #808080;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 19.2px */
}

.forme {
  /* outline: 2px solid red; */
  /* width: 100% !important; */
  margin: auto;
  margin-top: 2rem;
}

.mail {
  color: var(--Text-Color-1, #1b1b1b);
  /* font-family: Plus Jakarta Sans; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 19.2px */
}

.emailly {
  display: flex;
  width: 100%;
  height: 50px;
  /* padding: 17px 347px 17px 14px; */
  padding-left: 10px;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--Grey-4, #bdbdbd);
  background: #fff;
  color: #000;
}

.check {
  width: 100%;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  padding-top: 2rem;
}

.check p {
  color: #0cae18;
  /* Body Text */
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 19.2px */
}

.botty {
  margin-top: 2rem;
}

.longline {
  width: 80%;
  /* height: 1px; */
  /* background: #BDBDBD; */
  padding-top: 2rem;
  margin: auto;
}

.uppy {
  display: flex;
  justify-content: center;
  width: 80%;
  margin: auto;
  height: 10vh;
  margin-top: 2rem;
  /* background-color: red; */
}

.uppy p {
  color: var(--Text-Color-1, #1b1b1b);
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.up {
  color: var(--Tetiary, #0cae18);
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.email {
  padding-bottom: 2rem;
  width: 100%;
}

.required::after {
  content: ' *';
  color: red;
}


/* STYLING FOR FORGOT PASSWORD */
.send {
  color: #808080;
  text-align: center;
  /* font-family: Plus Jakarta Sans; */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 24px */
  padding-top: 2rem;

}

.contact {
  /* background-color: red; */
  display: flex;
  margin: auto;
  width: 90%;
  align-items: center;

}

.contact-text h2 {
  color: var(--Text-Color-1, #1B1B1B);
  font-family: Plus Jakarta Sans;
  font-size: 39px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  /* 46.8px */
  /* margin-top: 2rem;; */
}

.contact-text p {
  color: var(--Text-Color-1, #1B1B1B);
  text-align: justify;
  /* font-family: Plus Jakarta Sans; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 182%;
  /* 29.12px */
}

/* STYLING FOR CONTACT US */
.blueback {
  width: 80%;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  background: #133856;
  padding: 50px;
  margin: auto;
}

.formal {
  width: 50%;
}

.ema {
  display: flex;
  width: 100%;
  height: 162px;
  padding: 24px 326px 122px 14px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Grey-4, #BDBDBD);
  background: #FFF;
}

.bott {
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
}