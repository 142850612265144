.instant {
    width: 100%;
    display: flex;
    /* background-color: aqua; */
}

body {
    width: 100%;
    overflow-x: hidden;
}

.dashboard {
    width: 20%;
    background-color: #fff;
    height: 125vh;
    box-shadow: 5px 5px 5px 5px #888888;


}

.loggo {
    width: 100%;
    display: flex;
    justify-content: center;
}

.light {
    display: flex;
    width: 80%;
    margin: auto;

    /* outline: 2px solid blue; */
    /* justify-content: space-between; */
    padding-top: 2rem;
    align-items: center;
}

.panel {
    width: 80%;
}

.sidebar h3 {

    /* font-family: Plus Jakarta Sans; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 19.2px */
}

.sidebar {
    color: var(--Gey-3, #828282);

}

.sidebar:hover {
    color: var(--Gey-3, #828282);

}

.sidebar:hover {
    color: #fff;
}

.bar {
    margin-top: 2rem;
}

.icony {
    margin-right: 1rem;
}

.greeny {
    display: flex;
    border-radius: 8px;
    margin: auto;
    background: #0CAE18;
    margin-top: 7rem;
    width: 80%;
    justify-content: center;
    height: 75px;
    padding: 26px 68px 25px 46px;
}

.greeny h3 {
    color: #fff !important;
}

.grinny {
    display: flex;
}

.navie {
    background-color: #fff;
    width: 100%;
    height: 16vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    box-shadow: 5px 5px 5px 5px #888888;
}

.nave {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.nave h2 {
    color: var(--Text-Color-1, #1B1B1B);
    font-family: Plus Jakarta Sans;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 30px */
}

.nave p {
    color: var(--Grey-2, #4F4F4F);
    /* font-family: Plus Jakarta Sans; */
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.ovie:hover h3 {
    color: #fff;
    border-right: 7px solid #133856;
    background: #0CAE18;
    padding: 1rem;
    transition: color 0.3s;
    /* Add a smooth transition effect for the text color */
}

.sidebar h3 {
    color: var(--Gey-3, #828282);
    /* Change the text color on hover */
}


.buy {
    width: 80%;
    margin: auto;
    background-color: #0CAE18;
}

.boxy {
    background-color: #fff;
    width: 80%;
    height: 140vh;
    margin: auto;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    /* outline: 2px solid red; */
}

.formix {
    width: 100%;
    align-items: center;
    background-color: #BDBDBD;
    margin-top: 2rem;
    padding: 20px;
    border-radius: 15px;
    background: #F4F4F4;
    margin-left: 0 !important;

}

.forming {
    width: 80%;
    margin: auto;
    align-items: center;
    background-color: #fff;
    margin-top: 2rem;
    /* padding: 20px; */
    border-radius: 15px;
    /* background: #F4F4F4; */

}

.loading {
    width: 100%;
    height: 80vh;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
}

.input-color,
.loading {
    color: #1B1B1B;
}

.input-box {
    color: #1B1B1B;
    margin: 10px !important;
}

.emaili {
    display: flex;
    width: 100%;
    height: 45px;
    padding: 8.827px 14px 12.297px 14.255px;
    justify-content: center;
    align-items: flex-start;
    border-radius: 6.708px;
    border: 0.839px solid #BDBDBD;
    background: #FFF;
}

.buytoken {
    width: 85vw;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #1B1B1B;
}

.emaili option {
    color: black;
}

.emale {
    display: flex;
    width: 100%;
    height: 45px;
    padding: 8.827px 14px 12.297px 14.255px;
    justify-content: center;
    align-items: flex-start;
    border-radius: 6.708px;
    border: 0.839px solid #BDBDBD;
    background: #FFF;
}

.addy {
    color: var(--Tetiary, #0CAE18);
    /* font-family: Plus Jakarta Sans; */
    font-size: 13.417px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 16.1px */
}

.nummy {
    padding-top: 1.5rem;
}

.tetty {
    font-size: 30px;
}

.boxe h6,
li {
    color: #1B1B1B;
}

.boxe {
    background-color: #FFF;
    width: 80%;
    margin: auto;
    height: 120vh;
    padding: 20px;
}

.faint {
    border-radius: 8px 8px 0px 0px;
    background: #E6F6F4;
    width: 100%;
    padding: 20px;
}

.solo {
    width: 50px;
}



@media screen and (max-width: 1000px) {
    .instant {
        width: 100%;
    }

    .dashboard {
        display: none;
    }

    .navie {
        width: 100%;
    }

    .panel {
        width: 100%;
    }

    .nave {
        width: 60%;
        margin-right: 10%;
        position: relative;
    }

    .navie {
        position: relative;
        /* align-items: center; */
    }

    .hamie {
        position: absolute;
        display: block;
        left: 1rem;
        top: 5px;
        width: 25px !important;
    }

    .bm-overlay {
        margin-top: 2.5rem;
    }

    .bm-cross-button {
        margin-top: 2.5rem;
    }

    .light h3:hover {
        /* background-color: #fff; */
        color: #35A349;
        padding: 10px;
        border-radius: 5px;
        transition: all 0.3s;
    }

    .lace {
        margin-top: 3rem;
    }

    .greeny {
        background-color: #fff;
    }

    .greeny h3 {
        color: #35A349 !important;
    }
}