body {
  background-color: #f2f2f2;
  height: 100vh;
}

.sign {
  display: flex;
  width: 100%;
  height: 100vh;
}

.pixxy {
  background-image: url(../assets/setstart.svg);
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 50%;
  background-position: top center;
  background-color: #133856;

}

.logo {
  display: flex;
  justify-content: center;
  /* outline: 2px solid red; */
  align-items: center;
  padding-top: 10rem;
}

.formy {
  height: 100vh;
  width: 50%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.formy:hover {
  overflow-y: auto;
}

.formy h2 {
  color: var(--Text-Color-1, #1b1b1b);
  font-family: Plus Jakarta Sans;
  font-size: 39px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 46.8px */
  text-align: center;
  padding-top: 2rem;
}


.google p {
  color: var(--Text-Color-1, #1b1b1b);
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 19.2px */
}

.google {
  display: flex;
  padding: 13px 20px 13px 20px;
  width: 80%;
  margin: auto;
  margin-top: 2rem;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #fff;
  box-shadow: 4px 1px 4px 0px rgba(0, 0, 0, 0.25);
  /* outline: 2px solid red; */
}

.gogleicon {
  margin-right: 2rem;
}

.login {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: auto;
  margin-top: 2rem;
}

.log {
  color: #808080;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 19.2px */
}

.forme {
  /* outline: 2px solid red; */
  width: 80%;
  margin: auto;
  margin-top: 2rem;
}

.mail {
  color: var(--Text-Color-1, #1b1b1b);
  /* font-family: Plus Jakarta Sans; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 19.2px */
}

.emailly {
  display: flex;
  width: 447px;
  height: 50px;
  /* padding: 17px 347px 17px 14px; */
  align-items: center;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--Grey-4, #bdbdbd);
  background: #fff;
  color: #000;
}

.check {
  width: 100%;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  padding-top: 2rem;
}

.check p {
  color: #0cae18;
  /* Body Text */
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 19.2px */
}

.botty {
  margin-top: 2rem;
}

.longline {
  width: 80%;
  /* height: 1px; */
  /* background: #BDBDBD; */
  padding-top: 2rem;
  margin: auto;
}

.uppy {
  display: flex;
  justify-content: center;
  width: 80%;
  margin: auto;
  height: 10vh;
  margin-top: 2rem;
  /* background-color: red; */
}

.uppy p {
  color: var(--Text-Color-1, #1b1b1b);
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.up {
  color: var(--Tetiary, #0cae18);
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.email {
  padding-bottom: 2rem;
  width: 100%;
}

.emailExist {
  border: 1px solid red;
  display: flex;
  width: 447px;
  height: 50px;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  background: #fff;
  color: #000;
  padding: 10px;
}

.error {
  color: red;
}
@media (max-width:692px) {
  .sign{
    flex-direction: column;
    width: 100%;
  }
  .pixxy, .formy{
    width: 100% !important;
  }
}