p{
    color: #000;
}
.react-calendar button{
    color: #000 !important;
}
abbr{
    color: #000;
}


.react-calendar__navigation__label__labelText{
    color: #000;
}
.balance{
    display: flex;
    /* background-color: #00ffff; */
    margin-top: 3rem;
    margin-left: 3rem;
    width: 100%;
}
.bonus{
    display: flex;
    width: 40%;
    align-items: center;
    border-radius: 20px;
    background: #E6F6F4;
    padding: 10px;
    margin-right: 4rem;
 
}
.upper{
    margin-left: 20px;
}
.see{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.total{
    display: flex;
    align-items: center;
    border-radius: 20px;
    background: #E6F6F4;
    padding: 10px;
    width: 40%;
}
.lex{
    display: flex;
    align-items: center;
}
.twenty{
    display: flex;
    align-items: center;

}
.paying{
    margin-left: 2rem;
}
.monthly{
    border-radius: 5.396px;
    background: #0CAE18;
    margin-left: 3rem;
    margin-top: 2rem;
    padding: 10px;
    width: 25%;
    
}
.month{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cal{
    width: 20px;
    
}
.cal img{
    width: 100%;

}
.this{
    color: #fff;
}
.board{
    border-radius: 16px;
    background: #E6F6F4;
    margin-top: 3rem;
    margin-left: 3rem;
    width: 86%;
    height: 70vh;
    

}
.recent{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 16px;
}
.recent h4{
    color: #000;
    font-size: 20px;
    font-weight: bolder;
}
.recent p{
    color:#fff;
    border-radius: 8px;
    opacity: 0.8;
    background: #0CAE18;
    padding: 10px;

}
.mith{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 97%;
    margin: auto;
    padding: 12px;
    background-color: #fff;
    border-radius: 16px;
    margin-bottom: 2rem;
}
.disform{
    /* background-color: aqua; */
    display: flex;
    /* justify-content: space-between; */
}
.formix{
    margin-left: 4rem;
    margin-bottom: 2rem;
    position: relative;

}
.closeup{
    display: flex;
    /* align-items: end; */
    /* width: 100%; */
    position: absolute;
    right: 2%;
    padding: 10px;
    top: 0%;

    
}
.closeup img{
    /* width: 100%; */

}
#addside{
    background-color: #000;
    outline: 2px solid red;
}
.subby{
    border-radius: 30px;
    background: #B0E4DD;
    display: flex;
    width: 30%;
    margin: auto;
    margin-top: 5rem;
    padding: 10px;
    align-items: center;
}
.subly{
    display: flex;
    align-items: center;
    border-radius: 20px;
    background: var(--Light, #E6F6F4);
    margin-left: 2rem;
     color: #000;
     padding: 10px;
     margin-right: 2rem;
}
.non{
    background: var(--Light, #E6F6F4);
    border-radius: 20px;


}
.boardy{
    height: 140vh;
}
.numy{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30%;
    margin: auto;
    padding: 12px;
    background-color: #fff;
    border-radius: 16px;
    margin-bottom: 2rem;
}
.nims{
    display: flex;
    width: 40px;
    height: 40px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.nims:active{
    background: #0CAE18;

}
.wrody{
    border-radius: 30px;
    background: #E6F6F4; 
    width: 50%;
    margin: auto;
    padding: 20px;
    margin-top: 4rem;
    height: auto;
    margin-bottom: 4rem;
}
.transfer{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */

}
.transfer h4{
    margin-left: 10rem;
    color: #000;
    font-size: 20px;
    font-weight: bold;
}
.amount{
    margin-top: 2rem;
}
.selo{
    display: flex;
    margin-top: 2rem;
    align-items: center;
    position: relative;
}
.right{
    /* outline: 2px solid red; */
    margin-right: 3rem;
    position: absolute;
    right: 0;
    
}
.right img{
    width: 100%;

}
.emans{
    border-radius: 15px;
    border: 1px solid #BDBDBD;
    background: #FFF;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-top: 2rem;
    /* justify-content: space-between; */
}
.sach{
    margin-right: 2rem;
}
.access{
    display: flex;
    margin-top: 2rem;
    align-items: center;
}
.access p{
    margin-left: 2rem;
}
.union{
    width: 30px;
    height: 30px;
    border-radius: 50px;
    margin-right: 10px;
    /* outline: 2px solid red; */
    
}
.union img{
    width: 100%;

}
.accessy{
    border-radius: 15px;
    background: #9CFFA1;
    display: flex;
    margin-top: 2rem;
    align-items: center;
    padding: 10px;
}
.accessy p{
    margin-left: 10px;
}