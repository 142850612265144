:root {
  --color-primary: #6C9BCF;
  --color-danger: #FF0060;
  --color-success: #1B9C85;
  --color-warning: #F7D060;
  --color-white: #fff;
  --color-info-dark: #7d8da1;
  --color-dark: #000;
  --color-light: rgba(132, 139, 200, 0.18);
  --color-dark-variant: #677483;
  --color-background: #FFF;
  --color-back: #FFF;

  --card-border-radius: 2rem;
  --border-radius-1: 0.4rem;
  --border-radius-2: 1.2rem;

  --card-padding: 1.8rem;
  --padding-1: 1.2rem;

  --box-shadow: 0 2rem 3rem var(--color-light);
}

body {
  background-color: var(--color-back);
  color: var(--color-dark);
  /* overflow-x: hidden; */

}

.banner {
  z-index: 10;

  /* background-color: var(--color-background); */

}

.token-data {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 40px;
}

.hidde {
  background-color: var(--color-background);
  width: 100%;
  position: sticky !important;
  top: 0;
  box-shadow: 0 0 2px 2px black;
  height: 100px;
  z-index: 2;
}

.navy {
  background-color: var(--color-background);
  /* outline: 2px solid red; */
  display: flex;
  width: 90%;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  position: sticky !important;
  top: 0;
}

.navy .help {
  color: var(--color-dark);
}

.dark-mode-variables {
  --color-background: #181a1e;
  --color-back: #181a1e;
  --color-white: #202528;
  --color-dark: #edeffd;
  --color-dark-variant: #a3bdcc;
  --color-light: rgba(0, 0, 0, 0.4);
  --box-shadow: 0 2rem 3rem var(--color-light);
}

.light-mode-variables {
  --color-background: #f6f6f9;
  --color-white: #fff;
  --color-dark: #363949;
  --color-dark-variant: #677483;
  --color-light: rgba(132, 139, 200, 0.18);
  --box-shadow: 0 2rem 3rem var(--color-light);
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  appearance: 0;
  border: 0;
  text-decoration: none;
  box-sizing: border-box;
  color: var(--color-white);
}

.banner2 {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(color-white);

}

.colo {
  border-radius: 30px;
  background: var(--Light-Active, #B0E4DD);
  box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
  width: 20%;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
}

.phone-container {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5e5f65;
  gap: 10px;
}

.sech-input {
  outline: 0;
  border: 0;
  border-radius: 30px;
  padding: 10px;
  color: #000;
}

.dark-mode {
  background-color: var(--color-light);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.6rem;
  width: 4.2rem;
  cursor: pointer;
  border-radius: var(--border-radius-1);
}

.dark-mode span {
  font-size: 1.2rem;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.paystack-button {
  cursor: pointer;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  background-color: #bfbfbf;
  font-weight: bold;
  color: #e0eafc;
  border: none;
  border-radius: 5px;
  width: 100px;
  height: 50px;
  /* margin-top: 40px; */
  background-color: #3DBE46;
}

.dark-mode span.active {
  background-color: var(--color-primary);

  border-radius: var(--border-radius-1);
}

.buyes {
  /* color: var(--color-dark); */
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 70px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  /* 84px */
}

.button {
  z-index: 10;
}

.pay {
  margin-top: 2rem;
  /* color: var(--color-dark); */
}

.numba {
  color: #181a1e;
}

.electric-bar {
  /* outline: 2px solid red; */
}

.green {
  display: flex;
  height: 50px;
  padding: 17px 83px 17px 25px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Light-Hover, #D9F2EF);
  background: var(--Light, #E6F6F4);
  color: black;

}

/* YourComponent.css */

.green::placeholder {
  color: #3DBE46;
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.line {
  margin-left: 50%;
}

.all {
  background-image: url(./assets/backgr.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: darken;
  background-position: center;
  padding: 180px 0;
}
.black {
  color: #000;
}

.customer {
  font-weight: 500;
  font-size: 22px;
  color: black;
}

.error {
  color: red;
}

.become {
  color: #3DBE46;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 19.2px */
  text-align: center;
  margin-top: 2rem;
}
.roller{
  margin-top: 30px;
}
.skew {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.skill--box {
  background-color: whitesmoke;
  margin: 15px;
  width: 20%;
  height: 150px;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
}
.skill--box img{
  
  margin: auto;
  width: 100%;
  height: 100%;
}


.rollone {
  width: 120px;
  /* outline: 2px solid yellow; */
}

.rollone img {
  width: 100%;
}

.rollthree {
  width: 120px;
  /* outline: 2px solid yellow; */
}

.rollthree img {
  width: 100%;
}
.rap {
  width: 100%;
  background-color: var(--color-background);
}

.reap {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  /* background-color: #fff; */
  /* padding-top: 1rem; */
  /* margin-top: 4rem; */
  align-items: center;
  /* outline: 2px solid red; */
  /* position: relative; */
  padding: 50px 0;

}
.man{
  width: 60%;
  margin-top: 4rem;
}
.man img{
  width: 100%;
}
.bene {
  width: 50%;

}

.bene h2 {
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 49px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 58.8px */
  color: var(--color-dark);
}

.purchase {
  display: flex;
  margin-top: 1rem;
}

.purchase p {
  color: #000;
  text-align: justify;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 169.5%;
  /* 33.9px */
  margin-left: 0.5rem;
  color: var(--color-dark);

}

.support {
  background-image: url(./assets/Pattern.svg);
  background-color: #133856;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 180px 0;


}

.suppy {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 0; */

}

.twenty-four {
  width: 40%;
}

.twenty-four p {
  color: #FFF;
  text-align: justify;
  /* font-family: Plus Jakarta Sans; */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 169.5%;
  /* 33.9px */
  margin-top: 2rem;
}

.head {
  width: 70%;
}

.head img {
  width: 100%;
}

.blocky {
  background-color: #fff;
  width: 400.989px;
  height: 155.269px;
  padding: 20px;
  flex-shrink: 0;
  border-radius: 19.146px;
  background: #FFF;
  box-shadow: 0px 89.761px 145.862px 0px rgba(0, 0, 0, 0.05);
  position: absolute;
  bottom: 100px;
  margin-left: 10rem;
}

.blocky p {
  width: 310.949px;
  height: 95.194px;
  flex-shrink: 0;
  color: #3C405F;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 169.5%;
  /* 33.9px */
}

.cs {
  display: flex;
  width: 40%;
}

.cs p {
  width: 200px;
  color: #35A349;
  /* font-family: Plus Jakarta Sans; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 19.2px */
}

.twenty-four h2 {
  color: #FFF;
  font-family: Plus Jakarta Sans;
  font-size: 49px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 58.8px */
}

.settle {
  background-color: var(--color-background);
  margin-top: 5rem;
  height: 80vh;

}

.setty {
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: space-between;
  align-items: center;

}

.setup h2 {
  color: var(--Text-Color-1, #1B1B1B);
  font-family: Plus Jakarta Sans;
  font-size: 49px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  color: var(--color-dark);
  /* 58.8px */
}

.setup p {
  color: var(--Text-Color-1, #1B1B1B);
  text-align: justify;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 169.5%;
  /* 33.9px */
  margin-top: 1rem;
  color: var(--color-dark);
}

.setpp h2 {
  color: var(--Text-Color-1, #1B1B1B);
  font-family: Plus Jakarta Sans;
  font-size: 49px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* color: var(--color-dark); */
  /* 58.8px */
}

.setpp p {
  color: var(--Text-Color-1, #1B1B1B);
  text-align: justify;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 169.5%;
  /* 33.9px */
  margin-top: 1rem;
  width: 90%;
  /* margin: auto; */
  /* color: var(--color-dark); */
}

.remind {
  /* margin-top: 3rem; */

}

.remind img {
  width: 100%;

}

.banky {
  background: #C4D8E9;
  margin-top: 4rem;
}

.download {
  background-image: url(./assets/Pattern.svg);
  background-color: #133856;
  width: 100%;
  margin: auto;
  position: relative;
  height: 60vh;
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  /* padding: 20px; */
}

.download h2 {
  color: #FFF;
  font-family: Plus Jakarta Sans;
  font-size: 49px;
  font-style: normal;
  font-weight: 700;
  /* 58.8px */
  text-align: center;
  padding: 2rem;
}

.download img {
  width: 80%;
}

.phone3 {
  /* /* width: 100%; */
  margin-left: 65%;
  /* background-color: #1B9C85; */
  margin-top: 4.3rem;

}

.phone3 img {}

a {
  color: #f6f6f9;
}

.footer {
  background-color: #133856;
  color: #f6f6f9;
  height: 77vh;
  margin-top: 4rem;
  width: 100%;
}

.list {
  display: flex;
  width: 85%;
  margin: auto;
  justify-content: space-between;
  padding-top: 4rem;
}

.us p {
  color: #FFF;
  font-family: Plus Jakarta Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 30px */
  margin-top: 2rem;
}

.socials {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 2rem;
}

.soco {
  width: 18%;

}

.soco img {
  width: 100%;

}

.links li {
  list-style: none;

}

.liny {
  width: 80%;
  margin: auto;
  margin-top: 8rem;
}

.litttle img {
  width: 100%;
  /* margin: auto; */
}

.little {
  margin-top: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
  margin-top: 2rem;
  justify-content: center;
  color: #f6f6f9;
  /* color: var(--color-dark); */
}

.ball {

  animation: bounce 1s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
  animation-iteration-count: infinite;
}

.hamy {
  display: none;
}

.bm-overlay {
  display: none;
}

.bm-burger-button {
  display: none;
}

@keyframes bounce {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, 80px, 0);
  }
}

/* Prefix Support */

ball {
  -webkit-animation-name: bounce;
  -webkit-animation-duration: 1s;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
  -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 200px, 0);
    transform: translate3d(0, 50px, 0);
  }
}

.logosm {
  /* outline: 2px solid red; */
  margin-top: 1rem;
}

/* MOBILE VIEW */

@media screen and (max-width: 1000px) {

  nav {
    flex-direction: column;
    margin: 0;
  }

  .logosm {
    /* outline: 2px solid red; */
    /* margin-left: 0; */
    margin-top: 1rem;

  }

  .row {
    display: flex;
    width: 90vw !important;
    justify-content: center;
    align-items: center;
    border: 2px solid gold;
  }

  .column {
    display: flex;
    flex-direction: column;
    height: 90vh !important;
    justify-content: center;
    align-items: center;
    border: 2px solid gold;
  }

  .flex-center {}

  .navy {
    align-items: center;
    position: relative;
    display: flex;
    flex-direction: row;
  }

  .heppy {
    /* flex-direction: column; */
    width: 100%;
    /* align-items: flex-end; */
  }

  .cally {
    /* width: 100%; */
    /* align-items: center; */
  }

  .sech {
    flex-direction: column;

  }

  .banner {
    width: 95%;
  }

  .colo {
    width: 100%;
  }

  .fomy {}

  .sech-input {
    width: 90%;
  }

  .banner2 {
    width: 95%;
    padding-top: 100px;
  }

  .banner2 h1 {
    font-size: 40px;
  }

  .line {
    margin-left: 30%;
  }

  .pay {
    font-size: 18px;
    width: 90%;
  }

  .meter {
    flex-direction: column;
    width: 100%;
  }

  .entry {
    width: 100%;
  }

  .green {
    width: 100%;
    padding: 0;
    padding-left: 5px;
  }

  .green::placeholder {
    color: #3DBE46;
    /* font-family: Plus Jakarta Sans; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 120%;  */
    width: 100%;
  }

  .griny {
    margin-top: 1rem;
    width: 100%;
  }

  .become {
    /* margin-top: 5rem; */
    /* padding-top: 5rem; */
  }

  .marquee {
    overflow-x: hidden !important;
    height: auto;
    margin-top: 0;
    margin-bottom: 3rem;
  }
  .moving-images {
    width: 200px !important;
    /* height: 20vh; */
    padding-top: 10px;

  }

  .moving-images img {
    /* width: 100%; */

  }

  .reap {
    flex-direction: column;
  }

  .bene {
    width: 90%;
  }

  .bene h2 {
    font-size: 30px;
    width: 100%;
  }

  .start {
    width: 100%;
  }


  .blocky {
    top: 300px;
    margin-left: 0;
    width: 340px;
    /* display: none; */
  }

  .head {
    width: 100%;
    height: 100%;
  }

  .blocky p {
    width: 299.949px;
    height: 90.194px;
  }

  .cs p {
    width: 180px;
  }

  .suppy {
    flex-direction: column;
  }

  .twenty-four {
    width: 95%;
  }

  .twenty-four h2 {
    width: 100%;
    font-size: 30px;
    margin-top: 1rem;
  }

  .twenty-four p {
    width: 100%;
  }

  .setty {
    flex-direction: column;
    width: 95%;
    height: fit-content;
    /* margin-left: 2rem; */

  }

  .banky {
    width: 100%;
    /* outline: 2px solid red; */
    /* margin-left: 1rem; */
  }



  .setty h2 {
    font-size: 30px;
  }

  .setty {
    padding-bottom: 2rem;
  }

  .download h2 {
    font-size: 30px;
  }

  .get {
    width: 80%;
    justify-content: space-between;
  }

  .apple {
    margin-right: 1rem;
  }

  .phone3 {
    width: 100%;
    margin-top: 2.9rem;
    margin-left: 0;
  }

  .download {
    height: fit-content;
    /* margin-left: 2rem; */
  }

  .list {
    flex-direction: column;
  }

  .footer {
    height: 120vh;
    /* margin-left: 2rem; */
  }

  .footer a,
  p,
  li {

    color: white;
  }

  .links {
    margin-top: 2rem;
  }

  /* HAMBURGER STYLINGS */
  .hamy {
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    /* outline: 2px solid yellow; */
    width: 25px !important;
  }

  .bm-cross-button {
    /* outline: 2px solid red;  */
    position: absolute;
    right: 5px !important;
    top: 15px !important;
    /* outline: 2px solid yellow; */
    width: 25px !important;
    color: white;
  }

  .bm-menu {
    position: fixed;
    right: 0;
    display: block;
    background-color: #35A349;
    width: 100%;
    margin: auto;
    margin-top: 48px;
    padding: 40px 10px;

  }

  .menu {
    right: 0 !important;
    width: 100% !important;
  }

  .text-white {
    color: #f6f6f9;
  }

  /* .bm-overlay {
    background-color: #35A349 !important;
    display: block;
  } */

  .bm-item-list {
    width: 90%;
    margin: auto;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 30vh !important;
    /* margin-top: 2rem; */
  }

  .bm-item:hover {
    background-color: #fff;
    color: #35A349;
    padding: 10px;
    border-radius: 5px;
    transition: all 0.3s;
  }


  .heppy {
    /* display: none; */

  }

  .numba {
    margin-left: 0;
  }

  .bm-burger-button {
    display: block;
  }

  .heepy {
    display: flex;
  }

  .settle {
    height: fit-content;
  }


}

.modbtn {
  margin-left: 5%;
  margin-top: 5%;
  font-size: 20px;
  background-color: #0c0cb5;
  padding: 10px;
  border-radius: 5px;
}

.fast {
  text-align: center;
  margin-top: 4rem;
  font-size: 40px;
  background-color: #0c0cb5;
  display: flex;
  justify-content: center;
  margin: auto;
  border-radius: 100%;
  align-items: center;
  padding: 0 50px;
  width: 300px;
  height: 220px;
}

.styled-table {
  border-collapse: collapse;
  width: 100%;
}

.styled-table th,
.styled-table td {
  border: 1px solid #dddddd;
  padding: 8px;
}

.styled-table th {
  text-align: left;
  background-color: #171111;
}

.styled-table tbody tr:hover {
  background-color: #f5f5f5;
}

@media (max-width:450px) {
  .all{
    padding: 40px 0;
  }
  .skill--box{
    margin: 5px;
    width: 30%;
  }
  .support{
    padding: 70px 0;
  }
  .man{
    width: 90%;
  }
}